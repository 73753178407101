import builder from '@builder.io/react';

import { getBuilderJsonCacheKey } from './getBuilderJsonCacheKey';

export const getBuilderJson = async ({
  modelName,
  urlPath,
  query = {},
  additionalQueryParams = {},
  pageTargetingOptions = { userAttributes: {}, options: {} },
  skipUserAttributes = false,
}) => {
  const { userAttributes, options } = pageTargetingOptions;

  // this key is required to make sure we fire off queries
  // for all unique queries and that the Builder SDK does not
  // accidentally cache and return an incorrect value to us
  const key = getBuilderJsonCacheKey({
    modelName,
    urlPath,
    query,
    pageTargetingOptions,
    additionalQueryParams,
  });

  // this is due to the different attributes required between page
  // and section models, further explanation here:
  // https://confluence.techstyle.net/display/FLP/Builder.io+Model+User+Attributes
  const builderJson = await builder
    .get(modelName, {
      userAttributes: {
        ...(urlPath && { urlPath }),
        ...(!skipUserAttributes && { ...userAttributes }),
      },
      key,
      options,
      query,
      ...additionalQueryParams,
    })
    .promise();

  return { builderJson, key };
};
