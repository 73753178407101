import { getBuilderMembershipStatus } from '~/builder/utils/getBuilderMembershipStatus';
import { useLoyalty, useMembership } from '~/techstyle-shared/react-accounts';
import { useMarketingDisposition } from '~/techstyle-shared/react-marketing';
import { useSession } from '~/techstyle-shared/redux-core';

import { isSavage } from './brandNameHelper';

/**
 * Allows us to manage different load states in a single place.
 * This should be used to prevent us from re-rendering when a
 * state is not fully updated because multiple hooks update on a
 * single user action.
 */
export default function useLoadState() {
  const { isLoggedIn } = useSession();
  const loyalty = useLoyalty();
  const membership = useMembership();
  const disposition = useMarketingDisposition();

  const membershipStatus = getBuilderMembershipStatus(membership, isLoggedIn);

  let loggedInLoaded =
    isLoggedIn &&
    !!membershipStatus &&
    !!loyalty?.tier &&
    !!disposition?.experience;
  if (isSavage()) {
    loggedInLoaded = isLoggedIn && !!membershipStatus;
  }

  return { loggedInLoaded };
}
