import { useEffect, useMemo, useState } from 'react';

import useLoadState from '~/shared/utils/useLoadState';
import { useSession } from '~/techstyle-shared/redux-core';

import { getAllBuilderJson } from './getAllBuilderJson';
import { getBuilderJson } from './getBuilderJson';
import { getBuilderJsonCacheKey } from './getBuilderJsonCacheKey';
import useBuilderTargeting from './useBuilderTargeting';

export default function useBuilderJson({
  modelName,
  urlPath,
  query = {},
  builderQueries = { isPreview: false },
  ssrBuilderJson,
  ssrBuilderJsonKey,
  options = {},
  additionalQueryParams = {},
}) {
  const { isLoggedIn } = useSession();
  const [builderJsonResponses, setBuilderJsonResponses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { loggedInLoaded } = useLoadState();
  const lowerCaseUrlPath = urlPath?.toLowerCase();

  if (
    ssrBuilderJsonKey &&
    ssrBuilderJson &&
    !builderJsonResponses[ssrBuilderJsonKey]
  ) {
    setBuilderJsonResponses({
      ...builderJsonResponses,
      [ssrBuilderJsonKey]: ssrBuilderJson,
    });
  }

  const pageTargetingOptions = useBuilderTargeting({
    isPreview: builderQueries?.isPreview,
  });

  const cacheKey = useMemo(
    () =>
      getBuilderJsonCacheKey({
        pageTargetingOptions,
        query,
        urlPath: lowerCaseUrlPath,
        modelName,
        additionalQueryParams,
      }),
    [
      additionalQueryParams,
      lowerCaseUrlPath,
      modelName,
      pageTargetingOptions,
      query,
    ]
  );

  useEffect(() => {
    const getClientBuilderJson = async () => {
      let builderResponse;
      setIsLoading(true);

      if (options?.getAll) {
        builderResponse = await getAllBuilderJson({
          modelName,
          urlPath: lowerCaseUrlPath,
          query,
          pageTargetingOptions,
          additionalQueryParams,
          skipUserAttributes: options.skipUserAttributes || false,
        });
      } else {
        builderResponse = await getBuilderJson({
          modelName,
          urlPath: lowerCaseUrlPath,
          query,
          pageTargetingOptions,
          additionalQueryParams,
          skipUserAttributes: options.skipUserAttributes || false,
        });
      }
      setBuilderJsonResponses((prevBuilderJsonResponses) => ({
        ...prevBuilderJsonResponses,
        [builderResponse.key]: builderResponse.builderJson,
      }));
      setIsLoading(false);
    };

    // optimization check to prevent us from re-retrieving while we're in a "transition" user state
    const isUserStateInTransition = isLoggedIn && !loggedInLoaded;
    if (!isUserStateInTransition && !builderJsonResponses[cacheKey]) {
      getClientBuilderJson();
    }
    // the cacheKey has all the dependencies needed for this
    // if you add dependencies that are also in the cache key,
    // this will go into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey, loggedInLoaded]);

  return { builderJson: builderJsonResponses[cacheKey], isLoading };
}
