import config from 'config';
import { useRouter } from 'next/router';

import { urlNoQueryString, useDomain } from '~/techstyle-shared/redux-core';

const subDomain = config.get('public.brand.subDomain');

const useBrandDomainCanonicalLink = (canonicalPath = '') => {
  const { tld, name } = useDomain();
  const { asPath } = useRouter();

  const domain = `https://${subDomain}.${name}${tld}`;
  const path = canonicalPath || urlNoQueryString(asPath);
  const domainPath = `${path}`.includes(domain) ? '' : domain;

  return `${domainPath}${path}`;
};

export { useBrandDomainCanonicalLink };
