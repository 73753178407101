import React from 'react';

import Head from 'next/head';
import PropTypes from 'prop-types';

import { useBrandDomainCanonicalLink } from '~/shared/utils/useBrandDomainCanonicalLink';

export default function CanonicalLink({ canonicalPath }) {
  const href = useBrandDomainCanonicalLink(canonicalPath);

  return (
    <Head>
      <link rel="canonical" href={href} />
    </Head>
  );
}

CanonicalLink.propTypes = {
  canonicalPath: PropTypes.string,
};
