import crypto from 'crypto';

export function getBuilderJsonCacheKey({
  apiParamList,
  pageTargetingOptions,
  query,
  urlPath,
  modelName,
  additionalQueryParams = {},
}) {
  // this key is required to make sure we fire off queries
  // for all unique queries and that the Builder SDK does not
  // accidentally cache and return an incorrect value to us
  return crypto
    .createHash('sha256')
    .update(
      JSON.stringify({
        apiParamList,
        pageTargetingOptions,
        query,
        urlPath,
        modelName,
        ...additionalQueryParams,
      })
    )
    .digest('hex');
}
