import { trackBuilderTestEntered } from '../actions';

export function trackBuilderTest(dispatch, content) {
  if (content?.testRatio > 0 && content?.testRatio < 1) {
    dispatch(
      trackBuilderTestEntered({
        ...content,
      })
    );
  }
}
