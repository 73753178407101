import { Builder } from '@builder.io/react';

import { useIntl } from '~/techstyle-shared/react-intl';

export const useBuilderContent = (content) => {
  const { locale } = useIntl();
  const queryLocale =
    locale === 'en-US' && Builder.isPreviewing ? 'Default' : locale;

  return {
    content,
    locale: queryLocale,
  };
};
