import builder from '@builder.io/react';

import { getBuilderJsonCacheKey } from './getBuilderJsonCacheKey';

export const getAllBuilderJson = async ({
  modelName,
  urlPath,
  query = {},
  pageTargetingOptions = { userAttributes: {}, options: {} },
  additionalQueryParams = {},
  skipUserAttributes = false,
}) => {
  const { userAttributes, options } = pageTargetingOptions;

  // this key is required to make sure we fire off queries
  // for all unique queries and that the Builder SDK does not
  // accidentally cache and return an incorrect value to us
  const key = getBuilderJsonCacheKey({
    modelName,
    urlPath,
    query,
    pageTargetingOptions,
    ...additionalQueryParams,
  });

  const builderJson = await builder.getAll(modelName, {
    userAttributes: {
      ...(urlPath && { urlPath }),
      ...(!skipUserAttributes && { ...userAttributes }),
    },
    key,
    options,
    query,
    ...additionalQueryParams,
  });

  return { builderJson, key };
};
